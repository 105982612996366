import React, { Component } from "react"
import * as JsSearch from "js-search"
import getInventory from '../../providers/inventoryProvider.js'

import ListItem from '../components/ListItem'
import { slugify } from '../../utils/helpers'

class Search extends Component {

  constructor(props) {
    super(props)
    this.state = {
      itemList: [],
      search: [],
      searchResults: [],
      isLoading: true,
      isError: false,
      searchQuery: props.query,
    }
  }

  /**
   * React lifecycle method to fetch the data
   */
  async componentDidMount() {
    const inventory = await getInventory()
    this.setState({ itemList: inventory })
    this.rebuildIndex()
    this.searchData(this.state.searchQuery)
  }
  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const { itemList } = this.state
    const dataToSearch = new JsSearch.Search("name")
    /**
     *  defines a indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("name")
    dataToSearch.addIndex("name") // sets the index attribute for the data
    dataToSearch.addIndex("description") // sets the index attribute for the data
    dataToSearch.addDocuments(itemList) // adds the data to be searched
    this.setState({ search: dataToSearch, isLoading: false })
  }
  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = q => {
    const { search } = this.state
    const queryResult = search.search(q)
    this.setState({ searchQuery: q, searchResults: queryResult })
  }

  render() {
    const { itemList, searchResults, searchQuery } = this.state
    const queryResults = searchQuery === "" ? itemList : searchResults
    return (
      <>
        <div className="flex flex-col">
          <div className="max-w-fw flex flex-col">
            <div>
              <h1 className="text-5xl font-light">Results for: '{searchQuery}'</h1>
            </div>

            <div>
              Number of items: {queryResults.length}
            </div>
            <div className="flex flex-wrap flex-row">
              {
                queryResults.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      link={slugify(item.name)}
                      title={item.name}
                      price={item.price}
                      imageSrc={item.image}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Search