import React from 'react';
import { navigate } from "gatsby"
import { FaSearch } from 'react-icons/fa';
import { Link } from "gatsby"

class SearchField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
        }
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange = (e) => {
        this.setState({ query: e.target.value });
    }

    onKeyDown = (e) => {
        var x = e.which || e.keyCode
        if (x === 13) {
            this.handleSubmit();
        }
    }

    handleSubmit = () => {
        if (this.state.query.trim() !== '') {
            navigate("/search", { state: { query: this.state.query }, });
        }
    }

    render() {
        return (
            <div className="flex">
                <div className="w-full">
                    <div className="flex pb-6 items-center" >
                        <input
                            name="query"
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            className="text-xl shadow appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline" type="text" placeholder="Search for your perfect sofa here" />
                        <Link to={this.state.query.trim() !== '' ? '/search' : '/'} state={{ query: this.state.query }}>
                            <FaSearch className="p-2" onClick={this.handleSubmit} size={48} />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

}

export default SearchField;