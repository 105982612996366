/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { SiteContext, ContextProviderComponent } from '../context/mainContext'
import { titleIfy, slugify } from '../../utils/helpers'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import { colors } from '../theme'
import Footer from '../components/Footer'
import Nav from '../components/Nav'

toast.configure({
  progressStyle: {
    background: colors.primary,
  }
})

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <ContextProviderComponent>
        <SiteContext.Consumer>
          {
            context => {
              let { navItems: { navInfo: { data: links } } } = context

              links = links.map(link => ({
                name: titleIfy(link),
                link: slugify(link)
              }));
              links.unshift({
                name: 'Home',
                link: '/'
              })

              return (

                <div className="min-h-screen">
                  <nav>
                    <div className="flex justify-center">
                      <div className="w-fw mobile:px-10 desktop:px-0 px-4 pt-10 pb-6 flex flex-col sm:flex-row">
                        <div className="flex flex-grow">
                          <Nav />
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="mobile:px-10 px-4 pb-10 flex justify-center">
                    <main className="w-fw">{children}</main>
                  </div>
                  <Footer />
                </div>
              )
            }
          }
        </SiteContext.Consumer>
      </ContextProviderComponent>
    )
  }
}

export default Layout
