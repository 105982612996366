import React from "react"

import SEO from "../components/seo"
import CartLink from '../components/CartLink'
import SearchContainer from '../components/SearchContainer'

import { graphql } from 'gatsby'

const SearchPage = ({ data: gqlData, location }) => {
  const { categoryInfo: { data } } = gqlData

  return (
    <>
      <CartLink />
      <SEO title="Home" />
      <SearchContainer query={(location.state || {}).query} />
    </>
  )
}

export const pageQuery = graphql`
  query {
    navInfo {
      data
    }
    categoryInfo {
      data {
        name
        image
        itemCount
      }
    }
  }
`

export default SearchPage
