import React from 'react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { useStaticQuery, graphql } from "gatsby"

const Discount = (props) => {

    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                marketing {
                  promo_code
                  promo_discount
                }
              }
            }
          }
        `
    )

    return (
        <div className="bg-indigo-200 my-8 lg:h-30 p-8 pb-4 flex flex-row justify-center">
            <span className="px-2"><FaMoneyBillAlt size={24} /></span>
            <span className="flex flex-col">
                <p className="text-xl font-semibold">
                    {site.siteMetadata.marketing.promo_discount * 100}% off sitewide. Use code {site.siteMetadata.marketing.promo_code} at checkout*
                </p>
                <p className="text-xs">*Discount can not be combined with any other sale, promotion and/or offer.</p>
            </span>

        </div>
    )
}

export default Discount