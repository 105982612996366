import React from "react"
import { navigate } from "gatsby"

import { logout, isBrowser } from "../../utils/auth"

const Logout = () => {
    if (isBrowser) {
        logout();
        navigate("/")
    }
    return (<></>);
}

export default Logout;
