import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

function Footer({ text }) {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                copyright
              }
            }
          }
        `
    )

    return (
        <footer className="flex justify-center">
            <div className="flex w-fw px-8 desktop:px-0 border-solid border-t border-gray-300 items-center">
                <span className="block text-gray-700 pt-4 pb-8 mt-2 text-xs">{site.siteMetadata.copyright}</span>
            </div>
        </footer>
    )
}

Footer.defaultProps = {
    text: ``
}

Footer.propTypes = {
    text: PropTypes.string
}

export default Footer